<template>
    <div
        class="table-arrow-icon left"
        :class="{ disabled: !canScrollLeft }"
        @click="scrollLeft()"
    />
    <div
        class="table-arrow-icon right"
        :class="{ disabled: !canScrollRight }"
        @click="scrollRight()"
    />
</template>

<script>
export default {
    name: 'TableArrow',
    emits: ['update:viewportPositionX'],
    props: {
        viewportPositionX: {
            type: Number,
            default: 0
        },
        viewportWidth: {
            type: Number,
            default: 0
        },
        rowWidth: {
            type: Number,
            default: 0
        }
    },
    methods: {
        scrollLeft: function () {
            if (this.canScrollLeft) {
                this.viewportPositionXValue -= 120
                this.setGaEvent()
            }
        },
        scrollRight: function () {
            if (this.canScrollRight) {
                this.viewportPositionXValue += 120
                this.setGaEvent()
            }
        },
        setGaEvent: function () {
            this.$setGaEvent('clickArrow', 'click-TableTool')
        }
    },
    computed: {
        viewportPositionXValue: {
            get() {
                return this.viewportPositionX
            },
            set(val) {
                this.$emit('update:viewportPositionX', val)
            }
        },
        canScrollLeft: function () {
            return this.viewportPositionX > 0
        },
        canScrollRight: function () {
            const rightEdge = this.viewportWidth + this.viewportPositionX
            return rightEdge < this.rowWidth
        }
    },
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
.table-arrow-icon {
    cursor: pointer;
    position: absolute;
    top: clamp(60px, 40%, 200px);
    width: 40px;
    height: 40px;
    z-index: 5;
    &.left {
        left: -35px;
    }
    &.right {
        transform: rotate(180deg);
        right: -35px;
    }
    @media screen and (min-width: 426px) and (max-width: 1280px) {
        &.left {
            left: -2%;
        }
        &.right {
            right: -2%;
        }
    }
    @media screen and (min-width: 0px) and (max-width: 576px) {
        width: 30px;
        height: 30px;
        &.left {
            left: -4%;
        }
        &.right {
            right: -3%;
        }
    }
}
</style>
